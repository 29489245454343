import React, { useEffect, useState } from 'react';
import { InfoCard, Progress, Select } from '@backstage/core-components';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
  Legend,
} from 'recharts';
import { Grid, Typography } from '@material-ui/core';
import { useApi, discoveryApiRef, identityApiRef } from '@backstage/core-plugin-api';

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#AA336A',
  '#33AA99',
  '#AA99BB',
];

// Independent CostInfo function
export const CostInfo = () => {
  const [costData, setCostData] = useState<{ month: string; cost: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);

  useEffect(() => {
    const fetchCostData = async () => {
      try {
        const baseUrl = await discoveryApi.getBaseUrl('aws-costs');
        const { token } = await identityApi.getCredentials();

        const costResponse = await fetch(`${baseUrl}/cost-data`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!costResponse.ok) {
          throw new Error('Failed to fetch cost data');
        }

        const costData = await costResponse.json();
        setCostData(costData.months);
      } catch (error) {
        console.error('Error fetching cost data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCostData();
  }, [discoveryApi, identityApi]);

  if (loading) {
    return <Progress />;
  }

  const monthToDateCost = costData.length > 0 ? costData[costData.length - 1].cost : 0;
  const totalCostLast3Months = costData.slice(-3).reduce((acc, item) => acc + item.cost, 0);
  const comparisonLastTwoMonths = costData.length >= 3
    ? costData[costData.length - 2].cost - costData[costData.length - 3].cost
    : 0;
  const lastMonthTotalCost = costData.length >= 3 ? costData[costData.length - 2].cost : 0;

  return (
    <><Typography variant="h3">AWS Billing Dashboard</Typography><Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <InfoCard title="Month-to-Date Cost">
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                fontSize: '32px',
                fontWeight: 'bold',
                marginTop: '20px',
              }}
            >
              {`$${monthToDateCost.toFixed(2)}`}
            </div>
          </div>
        </InfoCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <InfoCard title="Total Cost for Last 3 Months">
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                fontSize: '32px',
                fontWeight: 'bold',
                marginTop: '20px',
              }}
            >
              {`$${totalCostLast3Months.toFixed(2)}`}
            </div>
          </div>
        </InfoCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <InfoCard title="Comparison of the Last Two Months">
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                fontSize: '32px',
                fontWeight: 'bold',
                color: comparisonLastTwoMonths < 0 ? 'green' : 'red',
                marginTop: '20px',
              }}
            >
              {comparisonLastTwoMonths < 0
                ? `$${Math.abs(comparisonLastTwoMonths).toFixed(2)}`
                : `$${comparisonLastTwoMonths.toFixed(2)}`}
            </div>
          </div>
        </InfoCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <InfoCard title="Last Month's Total Cost">
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                fontSize: '32px',
                fontWeight: 'bold',
                marginTop: '20px',
              }}
            >
              {`$${lastMonthTotalCost.toFixed(2)}`}
            </div>
          </div>
        </InfoCard>
      </Grid>
    </Grid></>
  );
};

// Independent CostDashboard function
export const CostDashboard = () => {
  const [serviceBreakdown, setServiceBreakdown] = useState<
    { service: string; cost: number; month: string }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedService, setSelectedService] = useState<string>(''); // Empty string indicates no selection
  const [serviceList, setServiceList] = useState<string[]>([]);
  const [filteredCostData, setFilteredCostData] = useState<
    { month: string; cost: number }[]
  >([]);

  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);

  useEffect(() => {
    const fetchServiceBreakdown = async () => {
      try {
        const baseUrl = await discoveryApi.getBaseUrl('aws-costs');
        const { token } = await identityApi.getCredentials();

        const breakdownResponse = await fetch(`${baseUrl}/service-breakdown`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!breakdownResponse.ok) {
          throw new Error('Failed to fetch service breakdown');
        }

        const breakdownData = await breakdownResponse.json();
        setServiceBreakdown(breakdownData);

        // Extract unique service names
        const services = Array.from(
          new Set(breakdownData.map(item => item.service)),
        ).sort();

        setServiceList(services);
      } catch (error) {
        console.error('Error fetching service breakdown:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceBreakdown();
  }, [discoveryApi, identityApi]);

  // Update filtered data based on selected service
  useEffect(() => {
    if (!selectedService) {
      // Aggregate total cost per month across all services
      const totalCostPerMonth = serviceBreakdown.reduce((acc, item) => {
        const month = item.month;
        if (!acc[month]) {
          acc[month] = { month: month, cost: 0 };
        }
        acc[month].cost += item.cost;
        return acc;
      }, {} as { [key: string]: { month: string; cost: number } });

      const totalCostData = Object.values(totalCostPerMonth).sort(
        (a, b) => new Date(a.month).getTime() - new Date(b.month).getTime(),
      );
      setFilteredCostData(totalCostData);
    } else {
      // Filter data for the selected service
      const filteredData = serviceBreakdown
        .filter(item => item.service === selectedService)
        .map(item => ({ month: item.month, cost: item.cost }))
        .sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime());
      setFilteredCostData(filteredData);
    }
  }, [selectedService, serviceBreakdown]);

  const aggregateServiceCosts = (data: { service: string; cost: number }[]) => {
    const serviceCosts = data.reduce((acc, item) => {
      const service = item.service;
      if (!acc[service]) {
        acc[service] = { service: service, cost: 0 };
      }
      acc[service].cost += item.cost;
      return acc;
    }, {} as { [key: string]: { service: string; cost: number } });

    return Object.values(serviceCosts);
  };

  const barChartData = aggregateServiceCosts(serviceBreakdown);

  if (loading) {
    return <Progress />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Typography variant="h3">AWS Billing Dashboard</Typography>
        <Select
          label="Filter by Service"
          selected={selectedService}
          onChange={setSelectedService}
          items={[
            { label: 'All Services', value: '' },
            ...serviceList.map(service => ({
              label: service,
              value: service,
            })),
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InfoCard title="AWS Cost Overview">
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={filteredCostData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="cost"
                    stroke="#8884d8"
                    name={
                      !selectedService
                        ? 'Total Monthly Cost (USD)'
                        : `${selectedService} Monthly Cost (USD)`
                    }
                  />
                </LineChart>
              </ResponsiveContainer>
            </InfoCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <InfoCard title="Cost Breakdown by Service">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={barChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="service" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="cost" fill="#8884d8" name="Cost (USD)">
                    {barChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </InfoCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
