import React, { useEffect, useState } from 'react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { awsCloudTrailApiRef } from '../api/AwsCloudTrailApi';
import { Progress, Table, TableColumn } from '@backstage/core-components';
import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  Paper,
  Card,
  CardContent,
} from '@material-ui/core';

// Define the type for a CloudTrail event row
export type AwsCloudTrailEventRow = {
  eventName: string;
  eventTime: string;
  username: string;
  fullEvent: string;
};

export const AwsCloudTrailComponent = () => {
  const awsCloudTrailApi = useApi(awsCloudTrailApiRef);
  const errorApi = useApi(errorApiRef);
  const [events, setEvents] = useState<AwsCloudTrailEventRow[]>([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState<any>(null);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const data = await awsCloudTrailApi.getEvents();
      const mappedData = data.events.map(event => ({
        eventName: event.EventName,
        eventTime: event.EventTime,
        username: event.Username,
        fullEvent: event.CloudTrailEvent ? JSON.parse(event.CloudTrailEvent) : event,
      }));
      setEvents(mappedData);
    } catch (error: Error | any) {
      errorApi.post(new Error(`Failed to fetch AWS CloudTrail events: ${error.message}`));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleOpenDialog = (fullEvent: any) => {
    setDialogContent(fullEvent);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogContent(null);
  };

  const renderContent = (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      return (
        <Card variant="outlined" style={{ marginBottom: '8px' }}>
          <CardContent>
            
            <Grid container spacing={2}>
              {Object.entries(value).map(([subKey, subValue]) => (
                <Grid item xs={12} key={subKey}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {subKey}:
                  </Typography>
                  <Typography variant="body1" style={{ marginLeft: '16px' }}>
                    {typeof subValue === 'object' ? JSON.stringify(subValue, null, 2) : subValue}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      );
    }
    return (
      <Typography variant="body1" style={{ marginLeft: '16px' }}>
        {value}
      </Typography>
    );
  };

  const columns: TableColumn<AwsCloudTrailEventRow>[] = [
    {
      title: 'Event Name',
      field: 'eventName',
    },
    {
      title: 'Event Time',
      field: 'eventTime',
      render: rowData => new Date(rowData.eventTime).toLocaleString(),
    },
    {
      title: 'Username',
      field: 'username',
    },
    {
      title: 'Details',
      field: 'fullEvent',
      render: rowData => (
        <Button onClick={() => handleOpenDialog(rowData.fullEvent)} color="primary">
          View Details
        </Button>
      ),
    },
  ];

  return (
    <Box>
      {loading ? (
        <Progress />
      ) : (
        <Table
          title="AWS CloudTrail Events"
          options={{ search: true, paging: true }}
          columns={columns}
          data={events}
        />
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Event Details</DialogTitle>
        <DialogContent>
          {dialogContent && (
            <Grid container spacing={2}>
              {Object.entries(dialogContent).map(([key, value]) => (
                <Grid item xs={12} key={key} component={Paper} variant="outlined" style={{ padding: '16px', marginBottom: '16px' }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {key}:
                  </Typography>
                  {renderContent(key, value)}
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
