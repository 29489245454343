import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { awsIamApiRef } from '../api/AwsIamApi';

interface IamUser {
  userName: string;
  userArn: string;
  lastActivity: string;
  consoleAccess: boolean;
  issue?: string;
}

export const IamUsersWidget = () => {
  const awsIamApi = useApi(awsIamApiRef);
  const [iamUsers, setIamUsers] = useState<IamUser[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchIamUsers = async () => {
      try {
        const response = await awsIamApi.getUsersWithIssues();
        setIamUsers(response.usersWithIssues);
      } catch (error) {
        console.error('Failed to fetch IAM users with issues', error);
      }
    };
    fetchIamUsers();
  }, [awsIamApi]);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleUserCardClick = (userName: string) => {
    window.open(
      `https://console.aws.amazon.com/iam/home?region=us-east-1#/users/${userName}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card
          style={{
            backgroundColor: iamUsers.length > 0 ? 'red' : 'green',
            cursor: 'pointer',
          }}
          onClick={handleOpenDialog}
        >
          <CardContent>
            <Typography variant="h6">AWS IAM Users with Issues</Typography>
            <Typography variant="subtitle1">
              Total IAM Users with Issues: {iamUsers.length}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>AWS IAM Users with Issues</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {iamUsers.map((user, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  onClick={() => handleUserCardClick(user.userName)}
                  style={{ cursor: 'pointer' }}
                >
                  <CardContent>
                    <Typography variant="h6">{user.userName}</Typography>
                    <Typography variant="body2">User ARN: {user.userArn}</Typography>
                    <Typography variant="body2">Last Activity: {user.lastActivity}</Typography>
                    <Typography variant="body2">
                      Console Access: {user.consoleAccess ? 'Yes' : 'No'}
                    </Typography>
                    {user.issue && (
                      <Typography variant="body2" color="error">
                        Issue: {user.issue}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
