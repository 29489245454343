import React, { useEffect, useState } from 'react';
import { Typography, Grid, Card, CardContent, Button, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText } from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { awsSecurityGroupsApiRef } from '../api/AwsSecurityGroupsApi';

export const SecurityGroupsWidget = () => {
  const awsSecurityGroupsApi = useApi(awsSecurityGroupsApiRef);
  const [securityGroups, setSecurityGroups] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<any | null>(null);

  useEffect(() => {
    const fetchSecurityGroups = async () => {
      try {
        const response = await awsSecurityGroupsApi.getSecurityGroupsWithIssues();
        setSecurityGroups(response.securityGroupsWithIssues);
      } catch (error) {
        console.error('Failed to fetch security groups with issues', error);
      }
    };
    fetchSecurityGroups();
  }, [awsSecurityGroupsApi]);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedGroup(null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card
          style={{
            backgroundColor: securityGroups.length > 0 ? 'red' : 'green',
            cursor: 'pointer',
          }}
          onClick={handleOpenDialog}
        >
          <CardContent>
            <Typography variant="h6">AWS Security Groups with Issues</Typography>
            <Typography variant="subtitle1">
              Total Security Groups with Issues: {securityGroups.length}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Security Groups with Issues</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {securityGroups.map((group, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card onClick={() => setSelectedGroup(group)} style={{ cursor: 'pointer' }}>
                  <CardContent>
                    <Typography variant="h6">{group.groupName || group.groupId}</Typography>
                    <Typography variant="body2">Region: {group.region}</Typography>
                    <Typography variant="body2">Issues: {group.issues ? group.issues.join(', ') : 'No issues'}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={!!selectedGroup} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Security Group Details</DialogTitle>
        <DialogContent>
          {selectedGroup && (
            <>
              <Typography variant="h6">
                <a
                  href={`https://console.aws.amazon.com/ec2/v2/home?region=${selectedGroup.region}#SecurityGroups:groupId=${selectedGroup.groupId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selectedGroup.groupName || selectedGroup.groupId}
                </a>
              </Typography>
              <Typography variant="body2">Region: {selectedGroup.region}</Typography>
              <Typography variant="body2">Description: {selectedGroup.description || 'N/A'}</Typography>
              <Typography variant="body2">
                Issues: {selectedGroup.issues ? selectedGroup.issues.join(', ') : 'No issues'}
              </Typography>
              <Typography variant="subtitle1">Inbound Rules with Issues:</Typography>
              <List>
                {selectedGroup.inboundRules && selectedGroup.inboundRules.length > 0 ? (
                  selectedGroup.inboundRules.map((rule: any, index: number) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={`Protocol: ${rule.IpProtocol}, From Port: ${rule.FromPort}, To Port: ${rule.ToPort}`}
                        secondary={`IP Ranges: ${rule.IpRanges ? rule.IpRanges.map((range: any) => range.CidrIp).join(', ') : 'N/A'}`}
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2">No inbound rules with issues</Typography>
                )}
              </List>
            </>
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};