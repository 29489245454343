import React, { useEffect, useState, ChangeEvent } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { tfStateApiRef, TfStateResource } from '../S3Api';
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Link,
  TextField,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { Progress } from '@backstage/core-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    tableContainer: {
      width: '100%', // Use 100% width of the parent Grid item
      maxHeight: 400,
      height:'100%'
    },
    categoryTitle: {
      textAlign: 'center',
      margin: theme.spacing(2, 0),
    },
    gridItem: {
      padding: theme.spacing(1),
    },
    noData: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    searchBar: {
      marginBottom: theme.spacing(2),
    },
  }),
);

export const TfStates = () => {
  const classes = useStyles();
  const api = useApi(tfStateApiRef);
  const [resources, setResources] = useState<TfStateResource[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const fetchResources = async () => {
    setLoading(true);
    try {
      const data = await api.getResources();
      setResources(data);
    } catch (err: any) {
      console.error('Failed to fetch tfstate resources:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.querySelectorAll('div > table').forEach(table => { const parentDiv = table.parentElement; if (parentDiv.tagName === 'DIV') { parentDiv.style.height = '100%'; } });
    fetchResources();
  }, [api]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filterResources = (resource: TfStateResource) => {
    if (searchTerm === '') return true;
    return resource.name.toLowerCase().includes(searchTerm) ||
           resource.type.toLowerCase().includes(searchTerm) ||
           resource.id.toLowerCase().includes(searchTerm);
  };

  const categorizeResources = () => {
    return resources.reduce((acc, resource) => {
      if (!filterResources(resource)) return acc;
      const type = resource.type.split('_')[1];
      const category = type.charAt(0).toUpperCase() + type.slice(1);
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(resource);
      return acc;
    }, {});
  };

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  const categorizedResources = categorizeResources();

 

  return (
    <div className={classes.container}>
      <Typography variant="h4">Terraform Managed Resources</Typography>
      <TextField
        label="Search Resources"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
        className={classes.searchBar}
        placeholder="Search by name, type, or ID"
      />
      <Grid container spacing={2} className='full-height'>
        {Object.entries(categorizedResources).map(([category, resources], idx) => (
          resources.length > 0 ? (
            <Grid item xs={12} sm={6} className={classes.gridItem} key={category}>
              <Typography variant="h6" className={classes.categoryTitle}>{category} Resources</Typography>
              <TableContainer component={Paper} className={classes.tableContainer}>
                <Table stickyHeader className='full-height'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resources.map((resource, index) => (
                      <TableRow key={index}>
                        <TableCell>{resource.name}</TableCell>
                        <TableCell>{resource.type}</TableCell>
                        <TableCell>{resource.id}</TableCell>
                        <TableCell>
                          {resource.url && (
                            <Link href={resource.url} target="_blank">View in AWS Console</Link>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null
        ))}
      </Grid>
    </div>
  );
};

export default TfStates;
