import { createApiRef, DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

export const awsSecurityGroupsApiRef = createApiRef<AwsSecurityGroupsApi>({
  id: 'plugin.aws-security.groups',
});

export interface AwsSecurityGroupsApi {
  getSecurityGroupsWithIssues(): Promise<any>;
}

export class AwsSecurityGroupsClient implements AwsSecurityGroupsApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly identityApi: IdentityApi,
  ) {}

  async getSecurityGroupsWithIssues(): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('aws-security');
    const url = `${baseUrl}/security-groups`;

    // Get the user's token
    const { token } = await this.identityApi.getCredentials();

    // Include the Authorization header
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch security groups with issues, status ${response.status}`);
    }

    return await response.json();
  }
}