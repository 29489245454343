import React from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import { Grid, Tabs, Tab, Typography, Card } from '@material-ui/core';
import { Content, PageWithHeader, SupportButton } from '@backstage/core-components';
import { AwsCloudTrailComponent, SecurityGroupsWidget, IamUsersWidget, AllIamUsersWidget } from '@internal/backstage-plugin-security';
import { AwsHealthComponent } from '@internal/backstage-plugin-aws-health-dashboard';


const InDevPlaceholder = ({  }) => (
  <Card style={{height: 'calc(100dvh - 200px)'}}>
    <div style={{
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%'
    }}
    className='empty-search-div'>
      <img src='/empty_search.png' style={{ maxWidth: '1000px', width: '100%', height: '100%', objectFit: 'contain' }}></img></div>
  </Card>
);

const Logs = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <AwsCloudTrailComponent />
    </Grid>
  </Grid>
);

const Monitoring = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <InDevPlaceholder 
        title="Security Monitoring" 
        description="Platform Security Monitoring will be displayed here." 
      />
    </Grid>
  </Grid>
);

const Overview = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Typography variant="h5">Overview</Typography>
    </Grid>
    <Grid item xs={4}>
      <SecurityGroupsWidget />
    </Grid>
    <Grid item xs={4}>
      <IamUsersWidget />
    </Grid>
    <Grid item xs={4}>
      <AllIamUsersWidget />
    </Grid>
    <Grid item xs={12}>
      <AwsHealthComponent />
    </Grid>
  </Grid>
);

const TabNavigation = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Tabs
      value={currentPath}
      aria-label="security navigation tabs"
      variant="scrollable"
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab label="Overview" value="/security" component={Link} to="/security" />
      <Tab label="Security Monitoring" value="/security/monitoring" component={Link} to="/security/monitoring" />
      <Tab label="Logs" value="/security/logs" component={Link} to="/security/logs" />
      <Tab label="Docs" value="/security/docs" component={Link} to="/docs" />
    </Tabs>
  );
};

// Main Security Page Component
export const SecurityPage = () => (
  <PageWithHeader themeId="tool" title="Security and Compliance">
    <Content>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <TabNavigation />
        <SupportButton>Project security page.</SupportButton>
      </div>
      <Routes>
        <Route path="/" element={<Overview />} />
        <Route path="/logs" element={<Logs />} />
        <Route path="/monitoring" element={<Monitoring />} />
      </Routes>
    </Content>
  </PageWithHeader>
);