import { createApiRef, DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

export const gitLabApiRef = createApiRef<GitLabApi>({
  id: 'plugin.itsgitlab.service',
});


export interface GitLabApi {
  getJobsByGroup(groupName: string): unknown;
  getIssuesByGroup(groupName: string): Promise<any[]>;
  getProjects(): Promise<any[]>;
  getLatestBuildByGroup(groupName: string): Promise<any[]>;
}

export class GitLabApiClient implements GitLabApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly identityApi: IdentityApi,
  ) {}
  async getProjects(): Promise<any[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('gitlab');
    const url = `${baseUrl}/projects`;

    // Get the user's token
    const { token } = await this.identityApi.getCredentials();

    // Include the Authorization header
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch GitLab projects, status ${response.status}`);
    }

    return await response.json();
  }
  async getIssuesByGroup(groupName: string): Promise<any[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('its-gitlab');
    const url = `${baseUrl}/issues/${groupName}`;

    const { token } = await this.identityApi.getCredentials();

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch issues for group ${groupName}, status ${response.status}`);
    }

    return await response.json();
  }
  async getLatestBuildByGroup(groupName: string): Promise<any[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('its-gitlab');
    const url = `${baseUrl}/latest-build/${groupName}`;

    const { token } = await this.identityApi.getCredentials();

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch latest builds for group ${groupName}, status ${response.status}`);
    }

    return await response.json();
  }
  async getJobsByGroup(groupName: string): Promise<any[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('its-gitlab');
    const url = `${baseUrl}/jobs/${groupName}`;

    const { token } = await this.identityApi.getCredentials();

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch jobs for group ${groupName}, status ${response.status}`);
    }

    return await response.json();
  }
}
