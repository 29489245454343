import React from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import { Grid, Tabs, Tab } from '@material-ui/core';
import { Content, PageWithHeader, SupportButton } from '@backstage/core-components';
import { GitLabGroupOverviewCard } from '@internal/backstage-plugin-user-managment';

// const InDevPlaceholder = ({ title, description }) => (
//   <Card style={{height: 'calc(100dvh - 200px)'}}>
//     <div style={{
//       display:'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       width: '100%',
//       height: '100%'
//     }}
//     className='empty-search-div'>
//       <img src='/empty_search.png' style={{ maxWidth: '1000px', width: '100%', height: '100%', objectFit: 'contain' }}></img></div>
//   </Card>
// );

const Users = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <GitLabGroupOverviewCard />
    </Grid>
  </Grid>
);


const Overview = () => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <Users />
    </Grid>
  </Grid>
);

const TabNavigation = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Tabs
      value={currentPath}
      aria-label="security navigation tabs"
      variant="scrollable"
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab label="Overview" value="/administration" component={Link} to="/administration" />
    </Tabs>
  );
};

// Main Security Page Component
export const AdministrationPage = () => (
  <PageWithHeader themeId="library" title="Platform Administration">
    <Content>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <TabNavigation />
        <SupportButton>Project security page.</SupportButton>
      </div>
      <Routes>
        <Route path="/" element={<Overview />} />
      </Routes>
    </Content>
  </PageWithHeader>
);