import React, { useState, useEffect } from 'react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { ecsServicesApiRef } from '../EcsServicesApi';
import { Progress, Table, TableColumn } from '@backstage/core-components';
import { Box, Button, Typography } from '@material-ui/core';

// Типи даних
type EnvVar = {
  name: string;
  value: string;
};

type Task = {
  taskArn: string;
  taskDefinitionArn: string;
  envVars: EnvVar[];
};

type EcsServiceRow = {
  serviceName: string;
  clusterArn: string;
  status: string;
  desiredCount: number;
  runningCount: number;
  region: string;
  tasks: Task[];
};

export const EcsServicesComponent = () => {
  const ecsServicesApi = useApi(ecsServicesApiRef);
  const errorApi = useApi(errorApiRef);
  const [services, setServices] = useState<EcsServiceRow[]>([]);
  const [loading, setLoading] = useState(true);

  // Функція для отримання сервісів
  const fetchServices = async () => {
    setLoading(true);
    try {
      const data = await ecsServicesApi.getEcsServices();
      setServices(data);
    } catch (error) {
      errorApi.post(new Error(`Failed to fetch ECS services: ${error.message}`));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
  }, []);

  const columns: TableColumn<EcsServiceRow>[] = [
    {
      title: 'Service Name',
      field: 'serviceName',
      render: rowData => (
        <a
          href={`https://${rowData.region}.console.aws.amazon.com/ecs/home?region=${rowData.region}#/clusters/${encodeURIComponent(
            rowData.clusterArn.split('/').pop()!,
          )}/services/${encodeURIComponent(rowData.serviceName)}/details`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {rowData.serviceName}
        </a>
      ),
    },
    {
      title: 'Status',
      field: 'status',
      render: rowData => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingX={1.5}
          paddingY={0.5}
          borderRadius={12}
          bgcolor={rowData.status === 'ACTIVE' ? '#90ee90' : '#a6a6a6'}
          width='max-content'
        >
          <Typography style={{ fontWeight: 'bold', color: rowData.status === 'ACTIVE' ? '#254225' : '#3d3d3d' }}>
            {rowData.status}
          </Typography>
        </Box>
      ),
    },
    { title: 'Desired Count', field: 'desiredCount', type: 'numeric' },
    { title: 'Running Count', field: 'runningCount', type: 'numeric' },
    { title: 'Region', field: 'region' },
    {
      title: 'Tasks / Env Vars',
      field: 'tasks',
      render: rowData => (
        <Box>
          {rowData.tasks.length > 0 ? (
            rowData.tasks.map(task => (
              <Box key={task.taskArn} mb={2}>
                <Typography variant="subtitle2">Task ARN:</Typography>
                <a
                  href={`https://${rowData.region}.console.aws.amazon.com/ecs/home?region=${rowData.region}#/clusters/${encodeURIComponent(
                    rowData.clusterArn.split('/').pop()!,
                  )}/tasks/${encodeURIComponent(task.taskArn.split('/').pop()!)}/details`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {task.taskArn}
                </a>
              </Box>
            ))
          ) : (
            <Typography>No tasks found.</Typography>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={fetchServices} disabled={loading}>
        Refresh
      </Button>
      {loading ? (
        <Progress />
      ) : (
        <Table
          title="ECS Services"
          options={{ search: true, paging: true }}
          columns={columns}
          data={services}
        />
      )}
    </Box>
  );
};