import { createApiRef, DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

// Create an API reference for AwsCloudTrailApi
export const awsCloudTrailApiRef = createApiRef<AwsCloudTrailApi>({
  id: 'plugin.aws-cloudtrail.service',
});
type AwsCloudTrailEventRow = {
    eventName: string;
    eventTime: string;
    username: string;
    fullEvent: string;
  };
// Define the AwsCloudTrailApi interface
export interface AwsCloudTrailApi {
  getEvents(): Promise<{ events: AwsCloudTrailEventRow[] }>;
}

// Implement the AwsCloudTrailApi interface
export class AwsCloudTrailClient implements AwsCloudTrailApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: { discoveryApi: DiscoveryApi; identityApi: IdentityApi }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  private async getAuthorizationHeader(): Promise<HeadersInit> {
    const { token } = await this.identityApi.getCredentials();
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }

  private async getBaseUrl(): Promise<string> {
    return await this.discoveryApi.getBaseUrl('aws-cloudtrail');
  }

  async getEvents(): Promise<{ events: AwsCloudTrailEventRow[] }> {
    const baseUrl = await this.getBaseUrl();
    const url = `${baseUrl}/events`;

    const response = await fetch(url, {
      headers: await this.getAuthorizationHeader(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch CloudTrail events, status ${response.status}`);
    }

    const data = await response.json();
    return data;
  }
}