import React, { useState } from 'react';

import { 
  makeStyles,
  Theme,
  createStyles,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { DashboardsComponent } from './DashboardsComponent';


interface BaseMetric {
  Id: string;
  Label: string;
  Timestamps: string[];
  Values: number[];
}

export interface MetricWithInstanceId {
  metric: BaseMetric;
  instanceId: string;
}

export interface MetricsResponse {
  [key: string]: MetricWithInstanceId[];
}

export const MetricsComponent = () => {

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });


  // Helper function to build chart data for line charts
  return (
    <div>
      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <div style={{ marginTop: '2rem' }}>
   
        <DashboardsComponent />
      </div>
    </div>
  );
};