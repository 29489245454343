import React, { useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { awsCloudWatchApiRef, Alarm } from '../AwsCloudWatchApi';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Link,
  Box,
} from '@material-ui/core';
import { Progress } from '@backstage/core-components';
import { Alert } from '@material-ui/lab';

const ALARM_STATE_COLORS: { [key: string]: string } = {
  OK: '#4caf50', // Зелений
  ALARM: '#f44336', // Червоний
  INSUFFICIENT_DATA: '#ff9800', // Помаранчевий
};

export const AlarmsComponent = () => {
  const awsCloudWatchApi = useApi(awsCloudWatchApiRef);
  const [loading, setLoading] = useState<boolean>(true);
  const [alarms, setAlarms] = useState<Alarm[]>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchAlarms = async () => {
      setLoading(true);
      try {
        const data: Alarm[] = await awsCloudWatchApi.getAlarms();
        setAlarms(data);
      } catch (err) {
        console.error('Error fetching alarms:', err);
        setError('Failed to load alarms. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAlarms();
  }, [awsCloudWatchApi]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (alarms.length === 0) {
    return <Typography variant="h6">No alarms found.</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>CloudWatch Alarms</Typography>
      <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Alarm Name</strong></TableCell>
              <TableCell><strong>Status</strong></TableCell>
              <TableCell><strong>Namespace</strong></TableCell>
              <TableCell><strong>Conditions</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alarms.map((alarm) => (
              <TableRow key={alarm.AlarmName}>
                <TableCell>
                  <Link href={alarm.url} target="_blank" underline="none" style={{ color: 'inherit' }}>
                    {alarm.AlarmName}
                  </Link>
                </TableCell>
                <TableCell>
                  <Chip
                    label={alarm.StateValue}
                    style={{
                      backgroundColor: ALARM_STATE_COLORS[alarm.StateValue] || 'default',
                      color: '#fff',
                    }}
                  />
                </TableCell>
                <TableCell>{alarm.Namespace}</TableCell>
                <TableCell>{alarm.conditions}</TableCell>
                {/* Додаткові колонки для інших деталей за необхідності */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};