import React, { useEffect, useState } from 'react';
import { Typography, Grid, Card, CardContent, Button, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText } from '@material-ui/core';
import { useApi } from '@backstage/core-plugin-api';
import { awsIamApiRef } from '../api/AwsIamApi';

interface IamUser {
  userName: string;
  userArn: string;
  lastActivity: string;
  consoleAccess: string;
}

interface IamResponse {
  users: IamUser[];
  userCount: number;
}

export const AllIamUsersWidget = () => {
  const awsIamApi = useApi(awsIamApiRef);
  const [iamUsers, setIamUsers] = useState<IamUser[]>([]);
  const [userCount, setUserCount] = useState<number>(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchIamUsers = async () => {
      try {
        const response: IamResponse = await awsIamApi.getAllUsers();
        setIamUsers(response.users);
        setUserCount(response.userCount);
      } catch (error) {
        console.error('Failed to fetch all IAM users', error);
      }
    };
    fetchIamUsers();
  }, [awsIamApi]);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card style={{ cursor: 'pointer' }} onClick={handleOpenDialog}>
          <CardContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <Typography variant="h6">AWS IAM Users</Typography>
              <Typography variant="subtitle1">Total IAM Users: {userCount}</Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              href="https://console.aws.amazon.com/iamv2/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open AWS IAM Console
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>IAM Users Details</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {iamUsers.map((user, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      <a
                        href={`https://console.aws.amazon.com/iam/home#/users/${user.userName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {user.userName}
                      </a>
                    </Typography>
                    <Typography variant="body2">User ARN: {user.userArn}</Typography>
                    <Typography variant="body2">Last Activity: {user.lastActivity}</Typography>
                    <Typography variant="body2">Console Access: {user.consoleAccess === 'Enabled' ? 'Yes' : 'No'}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};