import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { discoveryApiRef, identityApiRef } from '@backstage/core-plugin-api';
import { awsResourcesApiRef, AwsResourcesClient } from './components/AwsResourcesApi';

export const costsPlugin = createPlugin({
  id: 'costs',
  apis: [
    {
      api: awsResourcesApiRef,
      deps: { discoveryApi: discoveryApiRef, identityApi: identityApiRef },
      factory: ({ discoveryApi, identityApi }) => new AwsResourcesClient(discoveryApi, identityApi),
    },
  ],
  routes: {
    root: rootRouteRef,
  },
});
export const CostDashboardWidget = costsPlugin.provide(
  createComponentExtension({
    name: 'Cost dashboards',
    component: {
      lazy: () =>
        import('./components/CostDashboard').then(
          m => m.CostDashboard,
        ),
    },
  }),
);
export const CostsPage = costsPlugin.provide(
  createRoutableExtension({
    name: 'CostsPage',
    component: () =>
      import('./components/BillingAndHost').then(m => m.BillingAndHost),
    mountPoint: rootRouteRef,
  }),
);
