// plugins/gitlab/src/components/GitlabAssignedIssuesCard.tsx

import React, { useState, useEffect } from 'react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { gitLabApiRef } from './GitlabIssuesApi';
import { Progress, Table, TableColumn } from '@backstage/core-components';
import { Box, Dialog, DialogContent, DialogTitle, Typography, Avatar, Button, Link } from '@material-ui/core';
import { RELATION_OWNED_BY, EntityRelation } from '@backstage/catalog-model';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import dayjs from 'dayjs';

type GitLabIssue = {
  id: number;
  title: string;
  description: string;
  projectId: string;
  state: string;
  created_at: string;
  due_date: string | null;
  milestone?: { due_date: string | null };
  assignees: {
    id: number;
    username: string;
    name: string;
    avatar_url: string;
    web_url: string;
  }[];
  web_url: string;
  labels: string[];
};

type GitLabProjectGroup = {
  groupName: string;
  projectId: string;
};

export const GitLabAssignedIssuesComponent = ({ user, projectName }: { user?: string; projectName?: string }) => {
  const gitLabApi = useApi(gitLabApiRef);
  const errorApi = useApi(errorApiRef);
  const catalogApi = useApi<CatalogApi>(catalogApiRef);
  const [issues, setIssues] = useState<GitLabIssue[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState<GitLabIssue | null>(null);
  const [projectGroups, setProjectGroups] = useState<GitLabProjectGroup[]>([]);

  useEffect(() => {
    const fetchIssues = async () => {
      setLoading(true);
      try {
        const response = await catalogApi.getEntities({
          filter: [{ kind: 'component' }],
          fields: ['metadata', 'relations'],
        });

        const projectsGroups: GitLabProjectGroup[] = [];

        response.items.forEach((entity: any) => {
          const ownedByRelations =
            entity?.relations?.filter((r: EntityRelation) => r.type === RELATION_OWNED_BY) || [];
          ownedByRelations.forEach((relation: EntityRelation) => {
            const groupName = relation.targetRef.split('/')[1];
            if (groupName.toLowerCase() === 'guests') {
              return; // Skip fetching if group is 'guests'
            }
            const existingGroup = projectsGroups.find((group) => group.groupName === groupName);
            if (!existingGroup) {
              projectsGroups.push({ groupName, projectId: entity.metadata.name });
            }
          });
        });

        setProjectGroups(projectsGroups);

        const issuesPromises = projectsGroups.map((group) =>
          gitLabApi.getIssuesByGroup(group.groupName).then((issues) => ({ groupName: group.groupName, issues }))
        );
        const issuesResults = await Promise.all(issuesPromises);
        const allIssues: GitLabIssue[] = [];

        issuesResults.forEach(({ groupName, issues }) => {
          const projectGroup = projectsGroups.find((group) => group.groupName === groupName);
          if (projectGroup) {
            issues.forEach((issue) => {
              allIssues.push({
                ...issue,
                projectId: projectGroup.projectId,
                created_at: dayjs(issue.created_at).format('YYYY-MM-DD HH:mm:ss'),
                due_date: issue.milestone?.due_date
                  ? dayjs(issue.milestone.due_date).format('YYYY-MM-DD')
                  : issue.due_date
                  ? dayjs(issue.due_date).format('YYYY-MM-DD')
                  : '-',
              });
            });
          }
        });

        setIssues(allIssues);
      } catch (error: unknown) {
        if (error instanceof Error) {
          errorApi.post(new Error(`Failed to fetch issues for groups: ${error.message}`));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchIssues();
  }, [catalogApi, gitLabApi, errorApi]);

  const filteredIssues = issues.filter((issue) => {
    if (projectName && issue.projectId.toLowerCase() !== projectName.toLowerCase()) {
      return false;
    }
    if (user && !issue.assignees.some((assignee) => assignee.username.toLowerCase() === user.toLowerCase())) {
      return false;
    }
    return true;
  });

  const columns: TableColumn<GitLabIssue>[] = [
    { title: 'Title', field: 'title' },
    {
      title: 'Assignees',
      render: (rowData) => (
        <Box display="flex" alignItems="center">
          {rowData.assignees.map((assignee) => (
            <Box key={assignee.id} display="flex" alignItems="center" marginRight={1}>
              <Avatar
                src={assignee.avatar_url}
                alt={assignee.name}
                style={{ marginRight: 4, width: 16, height: 16 }}
              />
              <Link href={assignee.web_url} target="_blank" rel="noopener noreferrer">
                <Typography>{assignee.name}</Typography>
              </Link>
            </Box>
          ))}
        </Box>
      ),
    },
    { title: 'Created At', field: 'created_at' },
    { title: 'Due Date', field: 'due_date' },
    { title: 'Repository', field: 'projectId' },
    {
      title: 'State',
      render: (rowData) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingX={1.5}
          paddingY={0.5}
          borderRadius={12}
          bgcolor={rowData.state === 'opened' ? '#ff7f7f' : '#90ee90'}
          minWidth={80}
        >
          <Typography style={{ fontWeight: 'bold', color: 'black' }}>{rowData.state}</Typography>
        </Box>
      ),
    },
    {
      title: 'Labels',
      render: (rowData) => (
        <Box display="flex" alignItems="center">
          {rowData.labels.map((label) => (
            <Box
              key={label}
              display="flex"
              alignItems="center"
              justifyContent="center"
              marginRight={1}
              paddingX={2}
              paddingY={1}
              borderRadius={6}
              bgcolor={label.toLowerCase().includes('progress') ? '#ffeb3b' : '#add8e6'}
              minWidth={100}
            >
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>{label}</Typography>
            </Box>
          ))}
        </Box>
      ),
    },
  ];

  const handleRowClick = (event?: React.MouseEvent, rowData?: GitLabIssue) => {
    if (rowData) {
      setSelectedIssue(rowData);
    }
  };

  const handleCloseDialog = () => {
    setSelectedIssue(null);
  };
  const forproject = projectName ? projectName : 'all projects';

  return (
    <Box>
      {loading ? (
        <Progress />
      ) : (
        <Table
          title={`Gitlab Issues: ${forproject}`}
          subtitle={`Assigned to: ${user}`}
          options={{
            search: true,
            paging: true,
            sorting: true,
            defaultSortField: 'state',
            defaultSortOrder: 'asc',
          }}
          columns={columns}
          data={filteredIssues}
          onRowClick={handleRowClick}
        />
      )}
      {selectedIssue && (
        <Dialog open={true} onClose={handleCloseDialog}>
          <DialogTitle>{selectedIssue.title}</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1">Project ID: {selectedIssue.projectId}</Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingX={1.5}
              paddingY={0.5}
              borderRadius={12}
              bgcolor={selectedIssue.state === 'opened' ? '#ff7f7f' : '#90ee90'}
              marginBottom={1}
              minWidth={80}
            >
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>
                State: {selectedIssue.state}
              </Typography>
            </Box>
            <Typography variant="subtitle1">Created At: {selectedIssue.created_at}</Typography>
            <Typography variant="subtitle1">Due Date: {selectedIssue.due_date}</Typography>
            <Typography variant="subtitle1">Assignees:</Typography>
            <Box display="flex" alignItems="center">
              {selectedIssue.assignees.map((assignee) => (
                <Box key={assignee.id} display="flex" alignItems="center" marginBottom={1}>
                  <Avatar
                    src={assignee.avatar_url}
                    alt={assignee.name}
                    style={{ marginRight: 4, width: 20, height: 20 }}
                  />
                  <Link href={assignee.web_url} target="_blank" rel="noopener noreferrer">
                    <Typography>{assignee.name}</Typography>
                  </Link>
                </Box>
              ))}
            </Box>
            <Typography variant="subtitle1">Labels:</Typography>
            <Box display="flex" alignItems="center" marginBottom={1}>
              {selectedIssue.labels.map((label) => (
                <Box
                  key={label}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  marginRight={1}
                  paddingX={2}
                  paddingY={1}
                  borderRadius={6}
                  bgcolor={label.toLowerCase().includes('progress') ? 'yellow' : '#add8e6'}
                  minWidth={100}
                >
                  <Typography style={{ fontWeight: 'bold', color: 'black' }}>{label}</Typography>
                </Box>
              ))}
            </Box>
            <Typography variant="body1" style={{ marginTop: 16 }}>
              {selectedIssue.description}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href={selectedIssue.web_url}
              target="_blank"
              style={{ marginTop: 16 }}
            >
              Open in GitLab
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};
