import React, { useState, useEffect } from 'react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { gitLabApiRef } from './GitlabIssuesApi';
import { Progress, Table, TableColumn } from '@backstage/core-components';
import { Box, Dialog, DialogContent, DialogTitle, Typography, Avatar, Button, Select, MenuItem, Link } from '@material-ui/core';
import { RELATION_OWNED_BY, EntityRelation } from '@backstage/catalog-model';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import dayjs from 'dayjs';

type GitLabIssue = {
  id: number;
  title: string;
  description: string;
  projectId: string;
  projectName: string;
  groupName: string;  // Add groupName here for sorting
  state: string;
  created_at: string;
  due_date: string | null;
  assignees: {
    id: number;
    username: string;
    name: string;
    avatar_url: string;
    web_url: string;
  }[];
  web_url: string;
  labels: string[];
};

type GitLabProjectGroup = {
  groupName: string;
  projectIds: { id: string; name: string }[];
};

export const GitLabIssuesCard = () => {
  const gitLabApi = useApi(gitLabApiRef);
  const errorApi = useApi(errorApiRef);
  const catalogApi = useApi<CatalogApi>(catalogApiRef);
  const [issues, setIssues] = useState<GitLabIssue[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState<GitLabIssue | null>(null);
  const [projectGroups, setProjectGroups] = useState<GitLabProjectGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>('all');

  useEffect(() => {
    const fetchIssues = async () => {
      setLoading(true);
      try {
        const response = await catalogApi.getEntities({
          filter: [{ kind: 'component' }],
          fields: ['metadata', 'relations'],
        });

        const projectsGroups: GitLabProjectGroup[] = [];

        response.items.forEach((entity: any) => {
          const ownedByRelations = entity?.relations?.filter(
            (r: EntityRelation) => r.type === RELATION_OWNED_BY
          ) || [];
          ownedByRelations.forEach((relation: EntityRelation) => {
            const groupName = relation.targetRef.split('/')[1];
            if (groupName.toLowerCase() === 'guests') return;

            const existingGroup = projectsGroups.find(group => group.groupName === groupName);
            const projectId = entity.metadata.name;
            const projectName = entity.metadata.title || projectId;

            if (existingGroup) {
              existingGroup.projectIds.push({ id: projectId, name: projectName });
            } else {
              projectsGroups.push({
                groupName,
                projectIds: [{ id: projectId, name: projectName }],
              });
            }
          });
        });

        // Sort project groups by groupName for ordered dropdown display
        projectsGroups.sort((a, b) => a.groupName.localeCompare(b.groupName));
        setProjectGroups(projectsGroups);

        // Fetch issues for each group and map to include the project name and group name
        const issuesPromises = projectsGroups.map(group =>
          gitLabApi.getIssuesByGroup(group.groupName).then(issues => ({ groupName: group.groupName, issues }))
        );
        const issuesResults = await Promise.all(issuesPromises);
        const allIssues: GitLabIssue[] = [];

        issuesResults.forEach(({ groupName, issues }) => {
          const projectGroup = projectsGroups.find(group => group.groupName === groupName);
          if (projectGroup) {
            issues.forEach(issue => {
              allIssues.push({
                ...issue,
                projectId: issue.project_id,
                projectName: issue.project_name,
                groupName,  // Add groupName to the issue for sorting
                created_at: dayjs(issue.created_at).format('YYYY-MM-DD HH:mm:ss'),
                due_date: issue.milestone?.due_date
                  ? dayjs(issue.milestone.due_date).format('YYYY-MM-DD')
                  : issue.due_date
                  ? dayjs(issue.due_date).format('YYYY-MM-DD')
                  : '-',
              });
            });
          }
        });

        setIssues(allIssues);
      } catch (error: unknown) {
        if (error instanceof Error) {
          errorApi.post(new Error(`Failed to fetch issues for groups: ${error.message}`));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchIssues();
  }, [catalogApi, gitLabApi, errorApi]);

  const handleGroupChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedGroup(event.target.value as string);
  };

  const handleRowClick = (event?: React.MouseEvent, rowData?: GitLabIssue) => {
    if (rowData) {
      setSelectedIssue(rowData);
    }
  };

  const filteredIssues =
    selectedGroup === 'all'
      ? issues
      : issues.filter(issue => issue.groupName === selectedGroup);  // Filter issues based on the group name

  const columns: TableColumn<GitLabIssue>[] = [
    { title: 'Title', field: 'title' },
    {
      title: 'Assignees',
      render: (rowData) => (
        <Box display="flex" alignItems="center">
          {rowData.assignees.map(assignee => (
            <Box key={assignee.id} display="flex" alignItems="center" marginRight={1}>
              <Avatar src={assignee.avatar_url} alt={assignee.name} style={{ marginRight: 4, width: 16, height: 16 }} />
              <Link href={assignee.web_url} target="_blank" rel="noopener noreferrer">
                <Typography>{assignee.name}</Typography>
              </Link>
            </Box>
          ))}
        </Box>
      ),
    },
    { title: 'Created At', field: 'created_at' },
    { title: 'Due Date', field: 'due_date' },
    { title: 'Repository', field: 'projectName' },  // Project name (repository) should now be displayed correctly
    {
      title: 'State',
      field: 'state',
      render: (rowData) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          paddingX={1.5}
          paddingY={0.5}
          borderRadius={12}
          bgcolor={rowData.state === 'opened' ? '#90ee90' : '#a6a6a6'}
          width="max-content"
        >
          <Typography
            style={{
              fontWeight: 'bold',
              color: rowData.state === 'opened' ? '#254225' : '#3d3d3d',
            }}
          >
            {rowData.state}
          </Typography>
        </Box>
      ),
      sorting: true, // Enable sorting for the state
    },
    {
      title: 'Labels',
      render: (rowData) => (
        <Box display="flex" alignItems="center">
          {rowData.labels.map(label => (
            <Box
              key={label}
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingX={1.5}
              paddingY={0.5}
              borderRadius={12}
              bgcolor={label.toLowerCase().includes('progress') ? '#fff59c' : '#add8e6'}
              width="max-content"
            >
              <Typography style={{ fontWeight: 'bold', color: 'black' }}>{label}</Typography>
            </Box>
          ))}
        </Box>
      ),
    },
  ];

  return (
    <Box>
      {loading ? (
        <Progress />
      ) : (
        <Table
          title={
            <Box display="flex" alignItems="center">
              <Typography variant="h6" style={{ marginRight: 16 }}>
                GitLab Issues
              </Typography>
              <Select
                value={selectedGroup}
                onChange={handleGroupChange}
                displayEmpty
                variant="outlined"
                style={{ minWidth: 150 }}
              >
                <MenuItem value="all">All Groups</MenuItem>
                {projectGroups.map(group => (
                  <MenuItem key={group.groupName} value={group.groupName}>{group.groupName}</MenuItem>
                ))}
              </Select>
            </Box>
          }
          options={{ sorting: true }}
          columns={columns}
          data={filteredIssues}
          onRowClick={handleRowClick}
        />
      )}
      {selectedIssue && (
        <Dialog open={true} onClose={() => setSelectedIssue(null)}>
          <DialogTitle>{selectedIssue.title}</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1">Project: {selectedIssue.projectName}</Typography>
            <Typography variant="subtitle1">State: {selectedIssue.state}</Typography>
            <Typography variant="subtitle1">Created At: {selectedIssue.created_at}</Typography>
            <Typography variant="subtitle1">Due Date: {selectedIssue.due_date}</Typography>
            <Typography variant="body1">{selectedIssue.description}</Typography>
            <Button onClick={() => setSelectedIssue(null)} color="primary">Close</Button>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};
