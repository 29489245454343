import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { awsCloudWatchApiRef, AwsCloudWatchClient } from './AwsCloudWatchApi';

export const monitoringPlugin = createPlugin({
  id: 'monitoring',
  routes: {
    root: rootRouteRef,
  },
  apis: [
  createApiFactory({
    api: awsCloudWatchApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new AwsCloudWatchClient(discoveryApi, identityApi),
  }),
  ],
});

export const MonitoringPage = monitoringPlugin.provide(
  createRoutableExtension({
    name: 'MonitoringPage',
    component: () =>
      import('./components/MonitoringPage').then(m => m.MonitoringPage),
    mountPoint: rootRouteRef,
  }),
);
export const ECSwidget = monitoringPlugin.provide(
  createComponentExtension({
    name: 'ECS Services',
    component: {
      lazy: () =>
        import('./components/HomePage').then(
          m => m.EcsServiceCount,
        ),
    },
  }),
);
export const Lambdawidget = monitoringPlugin.provide(
  createComponentExtension({
    name: 'Lambda Functions',
    component: {
      lazy: () =>
        import('./components/HomePage').then(
          m => m.LambdaFunctions,
        ),
    },
  }),
);
export const EFSwidget = monitoringPlugin.provide(
  createComponentExtension({
    name: 'EFS',
    component: {
      lazy: () =>
        import('./components/HomePage').then(
          m => m.EfsDetails,
        ),
    },
  }),
);
export const RDSwidget = monitoringPlugin.provide(
  createComponentExtension({
    name: 'RDS Instnaces',
    component: {
      lazy: () =>
        import('./components/HomePage').then(
          m => m.RdsInstances,
        ),
    },
  }),
);